.App {
  text-align: center;
  overflow: hidden;
  font-family: "Roboto"sans-serif;
}

.margin-top {
  margin-top: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fullPageSpinner {
  z-index: "99";
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #5968bf;
}

.productPageSpinner {
  z-index: "99";
  position: fixed;
  top: 84%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #5968bf;
}