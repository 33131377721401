// Override default variables before the import
// $body-bg: #000;
// Import Bootstrap and its default variables
// @media (min-width: 320px) and (max-width: 575px) {
//   .App .content-wrapper {
//     width: 85%;
//   }
// }
.App .container {
  font-family: Arial;
  // @media (min-width: 1200px) {
  //   max-width: 1350px;
  // }
}

@import "~bootstrap/scss/bootstrap.scss";

@import "./modules/vendorPages/features/internal/header/header";
// @import "./modules/vendorPages/features/internal/tabs/tabsrow";
@import "./modules/vendorPages/features/themes/tabs/tabsrow";
@import "./modules/vendorPages/features/internal/product/gallery";
@import "./modules/vendorPages/features/internal/product/galleryHeader";

//Note: You must prefix imports from node_modules with ~ as displayed above. \\
