.gallery {
  .card {
    border: none;
    border-radius: 5px;
  }
  .img-wrapper {
    padding-top: 100%;
    img {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .card-body {
    text-align: left;
    padding: 25px 15px 20px;
    // background-color: #f6f6f7;
    h5,
    a {
      font-size: 16px;
      margin: 0;
      color: #212529;
    }
    .cost {
      color: #8c8c8c;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
    }
  }
  .card:hover .card-footer {
    box-shadow: 2px 2px 17px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
  }
  .card {
    transition: all 0.4s ease-in-out;
    &:hover {
      background-color: #ffffff;
      box-shadow: 0 0 14px 3px rgba(0, 0, 0, 0.05);
    }
  }
  .card:hover .btn-light {
    background-color: #ffffff;
  }
  .card-footer {
    background-color: #f6f6f7;
    border-top: none;
    padding: 1.5em 0;
  }
  //   .btn-light:hover {
  //     background-color: #f6f6f7;
  //   }

  // load more btn styles \\
  .btn-light {
    background-color: #f6f6f7;
    width: 90%;
    border: 1px solid #bfbfbf;
    padding: 0.75em;
  }
}
.gallery-bg > .container {
  @media screen and (min-width: 575px) {
    padding: 0 40px;
  }
}

.pagination {
  justify-content: center;
  justify-content: space-between;
  margin-top: 3rem;
}

.pro-load-more.load-more-border .default-btn {
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pro-load-more .default-btn {
  background-color: #f1f2f6;
  font-size: 14px;
  padding: 22px 46px;
}
.default-btn {
  color: #15273e;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  padding: 23px 50px 23px;
  text-transform: uppercase;
  z-index: 9;
}
.pagination a:hover {
  outline: medium none;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
  color: #fff;
}

.btn-hover::before {
  background: #343a40 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0 50% 0;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  z-index: -1;
}
.btn-hover:hover {
  position: relative;
}
.pagination .btn-hover:hover::before {
  transform: scaleX(1);
}
.page-item {
  padding: 0 3px;
  button {
    background: #fff;
    padding: 8px;
    border: 1px solid #999;
    color: #534f4f;
    min-width: 50px;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #333;
      border-color: #333;
      color: #fff;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .App .container .row {
    flex-wrap: wrap;
  }
  .col-md-3.col-sm-6.col-12 {
    padding-bottom: 0.5em;
  }
  // .gallery .card-body {
  //   padding: 27px 0 0;
  // }
}

@media (min-width: 320px) and (max-width: 575px) {
  span.cost {
    padding-right: 0.5em;
  }

  .col-md-3.col-sm-6.col-12 {
    display: flex;
    justify-content: center;
  }

  // .gallery-bg {
  //   .container:first-child {
  //     width: 85%;
  //   }
  // }
}
