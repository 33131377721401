.sort-drop {
  display: flex;
  // label {
  //   margin-top: 0.5rem;
  //   width: max-content;
  // }
  padding-right: 0;
  button.btn.btn-primary {
    color: #000;
    background-color: transparent;
    border-color: transparent;
  }
  button.btn.btn-primary:hover {
    color: #000;
    background-color: #e2e6ea;
    border-color: transparent;
  }
  button.btn.btn-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  button#dropdown-menu-align-right {
    background-color: none;
  }
}
//only if icons not available and to center align sortdrop div \\
.social {
  display: flex;
  justify-content: flex-end;
}
.gallery-icons {
  .dropdown-item {
    color: #6b747c;
    padding: 0.15rem 1rem;
    font-size: 13.9px;
    transition: all 0.3s ease-in-out;
    &:active,
    &.active,
    &:hover {
      background-color: transparent;
      color: #000;
      outline: 0;
    }
  }
}
.heading-gallery {
  h5 {
    text-align: left;
    @media screen and (min-width: 768px) {
      padding-left: 25px;
    }
  }
}
// .dropdown-margin {
//   margin-left: 1em;
//   margin-right: -1em;
// }

// @media (min-width: 321px) and (max-width: 575px) {
//   .gallery-bg .container:first-child {
//     width: 22rem;
//     display: flex;
//     justify-content: center;
//   }
// }
// @media (min-width: 320px) and (max-width: 341px) {
//   .gallery-bg .container:first-child {
//     width: auto;
//   }
// }
