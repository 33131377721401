.mc-about {
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .claim-btn {
    .btn {
      width: max-content;
    }
  }
}

.social-info {
  display: flex;
}
.info .list-group-item {
  border: none;
  // border-right: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 30px 0;
}
.social .list-group-item {
  border: none;
}
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link.active {
  color: #007bff;
  background-color: initial;
  box-shadow: inset 0 -0.06rem 0 #5c6ac4;
}
.nav-tabs .nav-link:hover {
  border-color: none;
}
.nav-tabs .nav-link {
  color: #495057;
  border: 0px;
}
.nav-tabs .nav-link:focus {
  // text-decoration: underline;
  outline: none;
  border-color: none;
}
.tab-content {
  padding: 1em 0 0em;
  text-align: left;
}
.brand-info {
  .nav-item {
    padding: 0;
    margin-right: 15px;
    color: #6e5e5580;
    box-shadow: inset 0 -0.06rem 0 #6e5e5580;
  }
}
.brand-name {
  h3 {
    font-size: 22px;
  }
  span {
    color: #999;
    padding-left: 5px;
  }
}
.logo-desc {
  // align-self: center;
  .logo {
    img {
      max-width: 180px;
      max-height: 110px;
    }
  }
}
#any-tab-about {
  padding-left: 0;
}
img {
  max-width: 100%;
}

.brand-logo img {
  width: 130px;
  height: 130px;
}
.wrapper {
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  border-radius: 4px;
  transition: box-shadow 0.5s linear;
  background-color: #fff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    min-height: 200px;
  }
}

.container-wrapper {
  cursor: pointer;
  // max-width: 600px;
  margin: 15px;
  min-width: 250px;
  flex: calc(25% - 30px);
  display: inline-block;
  border: 0;
  box-sizing: content-box;
}

.content-wrapper {
  > .container:first-child {
    margin-top: -8rem;
  }
}
#dropdown-menu-align-right {
  background: #fff;
  padding: 10px 0;
  text-align: left;
  font-size: 14px;
  padding: 13px 0;
  width: 90px;
  color: #6b747c;
  @media screen and (min-width: 575px) {
    width: 100px;
  }
  &:after {
    position: absolute;
    right: 10px;
    top: 23px;
  }
  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
}
.filter-toolbar {
  .form-control {
    height: auto;
    padding: 10px 10px 10px 35px;
    border: 0;
    font-size: 14px;
    border: 1px solid #ccc;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .search-icon {
    position: absolute;
    left: 12px;
    top: 12px;
    color: #999;
  }
}
// .contact-info {
//   background: #f9f9f9;
// }

address {
  color: #212529;
  h4 {
    font-size: 20px;
  }
  span {
    a {
      color: #212529;
      word-break: break-all;
    }
  }
}
img {
  max-width: 100%;
}
@media (min-width: 320px) and (max-width: 767px) {
  address {
    flex-wrap: wrap;
    width: auto;
    .address span.company {
      margin-left: 0.4rem;
    }
    .address span.addrs {
      margin-left: 0.8rem;
    }
    .address span.website {
      margin-left: 1rem;
    }
  }
  .tab-content {
    height: fit-content;
  }
}
