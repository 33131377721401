.top-bar {
  height: 3.5rem;
}
header {
  .poster-img {
    max-height: 450px;
    overflow: hidden;

    img {
      object-fit: cover;
      min-height: 300px;
      width: 100%;
    }
  }
}
